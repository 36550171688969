.hexagrams {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 20px;
  }
  
  .hexagram {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .trigram-container {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items horizontally within the container */
  }

  .trigram-lines {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 10px; /* Adjust spacing between lines and element */
  }

  .trigram-element {
    /* Additional styling for the element text */
  }

  .trigram {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .line {
    width: 40px;
    height: 4px;
    margin-bottom: 4px;
  }
  
  .yang {
    background-color: black;
  }
  
  .yin {
    background-color: white;
    border-top: 2px solid black;
    border-bottom: 2px solid black;
  }
  
  .meaning {
    font-weight: bold;
    text-align: center;
  }

  .yang-line {
    border-top: 3px solid black;
    width: 100px;
    animation: drawLine 1s ease-in-out;
  }
  
  .yin-line {
    display: flex;
    justify-content: space-between;
    width: 100px;
    animation: drawLine 1s ease-in-out;
  }

  .yang-line, .yin-line {
    margin-top: 4px; /* Adjust the value as needed */
    margin-bottom: 4px; /* Adjust the value as needed */
  }
  
    .yin-part {
    border-top: 3px solid black;
    width: 45%;
  }

  .hexagram > * {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
  
  .hexagram {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    animation: pulseHexagram 2s 2;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes pulseHexagram {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  
  @keyframes drawLine {
    0% {
      width: 0;
    }
    100% {
      width: 100px;
    }
  }