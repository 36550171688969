@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

.app {
  display: flex; /* Establishes a flex container, enabling the use of flexbox properties for its children. */
  flex-direction: column; /* Arranges the flex container's children (flex items) in a column, stacking them vertically. */
  align-items: center; /* Centers the flex items on the cross axis (horizontally, since the flex-direction is column). */
  justify-content: center; /* Centers the flex items along the main axis (vertically, in this case). */
  /*height: 100vh; /* Sets the height of the .app container to be 100% of the viewport height. */
  background-color: #f0f0f0; /* Sets the background color of the .app container to a light grey. */
  /*min-height: 100vh; /* Ensures that the minimum height of the .app container is never less than 100% of the viewport height. */
}

.title {
  font-family: 'Montserrat', sans-serif;
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 40px;
  color: #333;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  animation: fadeIn 1s ease-in-out;
}

.app-footer {
  text-align: center; /* Aligns the text inside the footer to the center. */
  padding: 20px; /* Adds space inside the footer, around its content. This is 20 pixels on all sides. */
  /*position: fixed; /* Positions the footer relative to the viewport, which means it will stay in the same place even when the page is scrolled. */
  left: 0; /* With position fixed, this sets the left edge of the footer to align with the left edge of the viewport. */
  bottom: 0; /* With position fixed, this sets the bottom edge of the footer to align with the bottom edge of the viewport, effectively sticking it to the bottom. */
  /*width: 100%; /* Makes the footer extend the full width of the viewport. */
  background-color: #f0f0f0; /* Sets the background color of the footer. The color is a light grey. */
  color: black; /* Sets the text color inside the footer to black. */
}

.hexagram-explanation {
  width: 40%; /* Take up one-third of the container's width */
  margin: 0 auto; /* Center the div horizontally */
  /*padding: 20px; /* Optional: Adds some padding inside the div */
  box-sizing: border-box; /* Ensures padding does not affect the total width */
  max-height: 100vh; /* or any specific height */
  flex: 0 1 auto; /* This item can grow and shrink but has an automatic base size */
  overflow-y: auto; /* Allows vertical scrolling */
}

@media (max-width: 768px) {
  .hexagram-explanation {
    width: 80%; /* Increase width on screens smaller than 768px */
    /* Adjust other properties as needed for mobile */
  }
}